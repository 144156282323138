import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import SEO from "../../components/seo"
import GalleryLayout from "../../layouts/galleryLayout"
import GalleryImageLink from "../../components/galleryImageLink"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;

  @media (max-width: 700px) {
    margin: 0 -0.5rem;
  }
`

const ImageContainer = styled(motion.div)`
  width: 33.33%;
  padding: 0.75rem;

  @media (max-width: 700px) {
    width: 50%;
    padding: 0.5rem;
  }
`

export default function Grid({ data, location }) {
  const [nodes] = data.allGalleryJson.edges
  const { category, description, title, hero } = nodes.node

  return (
    <GalleryLayout>
      <SEO
        description={description}
        title={title}
        image={hero.cloudinary.fixed.src}
        pathname={location.pathname}
      />
      <Wrapper>
        {nodes.node.images.map((node, index) => {
          return (
            <ImageContainer
              key={node.name}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0 + index * 0.02 }}
            >
              <GalleryImageLink
                category={category}
                name={node.name}
                fluid={{ ...node.src.cloudinary.fluid, aspectRatio: 1 }}
              />
            </ImageContainer>
          )
        })}
      </Wrapper>
    </GalleryLayout>
  )
}

export const query = graphql`
  query($category: String!) {
    allGalleryJson(filter: { category: { eq: $category } }) {
      edges {
        node {
          category
          title
          description
          hero {
            cloudinary: childCloudinaryAsset {
              fixed(transformations: ["t_og_image", "f_jpg"]) {
                ...CloudinaryAssetFixed
              }
            }
          }
          images {
            location
            name
            src {
              cloudinary: childCloudinaryAsset {
                fluid(
                  maxWidth: 600
                  transformations: ["t_crop_600x600", "f_auto"]
                ) {
                  ...CloudinaryAssetFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
